import React from "react";
import logoNapis from "../../media/dhdesigns/cerne-logo-napis.svg";
import "./FooterModule.css"

const FooterModule = () => {

    return <>
        <div className="footerWrapper">
            <div className="footerInformations">
                <p>Daniel Hrečin</p>
                <p>IČO: </p>
                <p>Design | Web Development</p>
                <p>email: <a target="_blank" rel="noopener noreferrer" className="a" href="mailto:daniel.dhdesigns@gmail.com">daniel.dhdesigns@gmail.com</a></p>
                <p>Telefon: <a target="_blank" rel="noopener noreferrer" className="a" href="tel:+420773737985">+420 773 737 985</a></p>
                <p>Instagram: <a target="_blank" rel="noopener noreferrer" className="a" href="https://www.instagram.com/dhdes1gns">@dhdes1gns</a></p>
                <p>GitHub: <a target="_blank" rel="noopener noreferrer" className="a" href="https://github.com/DHDesignsGit">DHDesignsGit</a></p>
            </div>
            <div className="footerImgs">
                <img className="footerLogo" src={logoNapis} alt="logo s nápisem" />
            </div>
        </div>
    </>
}

export default FooterModule;