import React from "react";

import img1 from "../../media/downloadedSvgs/analyze-on-screen.svg"
import img2 from "../../media/downloadedSvgs/working-with-laptop.svg"

const PortfolioPage = () => {
    return <>
        <div className="section">
            <div className="animated-left">
                <h1>Projekty</h1>
                <h2>Design</h2>
                <p><a target="_blank" rel="noopener noreferrer" className="a" href="https://github.com/DHDesignsGit/t2-club-design.git">Nápojový lístek pro T2 Club. </a>(Vytvořil jsem minimalistický komerčně standardní design. Lístek obsahuje dvě strany: stranu s logem a samotný nápojový lístek. Jelikož se ke mně dostalo logo vytvořené v rastrové grafice a nemělo dobré rozlišení, logo jsem překreslil do vektorové grafiky. Logo je k dispozici ve formátech ".jpeg, .png, .svg a .ico" v adresáři "logo".)</p>
                <h2>Web Development</h2>
                <p><a target="_blank" rel="noopener noreferrer" className="a" href="https://signpost.stornoband.com/">Webový rozcestník pro kapelu Storno</a>. (Na koncertech mají u podia QR kod, který odkazuje na tuto stránku.)</p>
            </div>
            <div className="animated-right"><img src={img1} alt="" /></div>
        </div>
        <div className="section">
            <div className="animated-left img1"><img src={img2} alt="" /></div>
            <div className="animated-right">
                <h1>Technologie</h1>
                <h2>Design</h2>
                <p><a className="a" href="https://www.figma.com/" target="_blank" rel="noopener noreferrer">Figma</a> - Nástroj pro návrh UI/UX a prototypování</p>
                <p><a className="a" href="https://www.adobe.com/products/photoshop.html" target="_blank" rel="noopener noreferrer">Photoshop</a> - Nástroj pro úpravu fotografií a grafický design</p>
                <p><a className="a" href="https://www.adobe.com/products/illustrator.html" target="_blank" rel="noopener noreferrer">Illustrator</a> - Nástroj pro vektorovou grafiku a ilustrace</p>
                <p><a className="a" href="https://www.adobe.com/products/animate.html" target="_blank" rel="noopener noreferrer">Animate</a> - Nástroj pro tvorbu animací a interaktivních obsahů</p>
                <p><a className="a" href="https://www.adobe.com/products/premiere.html" target="_blank" rel="noopener noreferrer">Premiere Pro</a> - Nástroj pro střih a úpravu videí</p>
                <p><a className="a" href="https://www.adobe.com/products/aftereffects.html" target="_blank" rel="noopener noreferrer">After Effects</a> - Nástroj pro tvorbu vizuálních efektů a pohybové grafiky</p>

                <h2>Web Development</h2>
                <p><a className="a" href="https://developer.mozilla.org/en-US/docs/Web/HTML" target="_blank" rel="noopener noreferrer">HTML</a> - Značkovací jazyk pro tvorbu webových stránek</p>
                <p><a className="a" href="https://developer.mozilla.org/en-US/docs/Web/CSS" target="_blank" rel="noopener noreferrer">CSS</a> - Stylovací jazyk pro úpravu vzhledu webových stránek</p>
                <p><a className="a" href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank" rel="noopener noreferrer">JavaScript</a> - Programovací jazyk pro tvorbu interaktivních prvků na webových stránkách</p>
                <p><a className="a" href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> - JavaScriptová knihovna pro tvorbu uživatelských rozhraní</p>
                <p><a className="a" href="https://nodejs.org/" target="_blank" rel="noopener noreferrer">Node.js</a> - JavaScriptové runtime prostředí pro serverovou stranu</p>
                <p><a className="a" href="https://www.python.org/" target="_blank" rel="noopener noreferrer">Python</a> - Programovací jazyk pro různé druhy vývoje, včetně webu a datové analýzy</p>
                <p><a className="a" href="https://firebase.google.com/" target="_blank" rel="noopener noreferrer">Firebase</a> - Platforma pro vývoj mobilních a webových aplikací s backendovými službami</p>
                <p><a className="a" href="https://www.figma.com/" target="_blank" rel="noopener noreferrer">Figma</a> - Nástroj pro návrh UI/UX a prototypování</p>
            </div>
        </div>
    </>
}

export default PortfolioPage;