import React from "react";

import image from "../../media/downloadedSvgs/calendar.svg"

const ContactsPage = () => {
    return <>
    <div className="section">
    <div className="animated-left">
    <p>Pokud máte zájem, napište mi prosím email. Email by měl obsahovat veškeré vaše požadavky a pokud je to potřeba, tak i soubory, se kterýma bych měl pracovat. Potom se domluvíme na veškerých podmínkách a já začnu pracovat na vaší zakázce.</p>
    <p>email: <a target="_blank" rel="noopener noreferrer" className="a" href="mailto:daniel.dhdesigns@gmail.com">daniel.dhdesigns@gmail.com</a></p>
    <p>Telefon: <a target="_blank" rel="noopener noreferrer" className="a" href="tel:+420773737985">+420 773 737 985</a> (Volejte, pokud možno, vždy mezi 16:00 - 20:00.)</p>
    <p>Instagram: <a target="_blank" rel="noopener noreferrer" className="a" href="https://www.instagram.com/dhdes1gns">@dhdes1gns</a></p>
    <p>GitHub: <a target="_blank" rel="noopener noreferrer" className="a" href="https://github.com/DHDesignsGit">DHDesignsGit</a></p>

    </div>
    <div className="animated-right" ><img src={image} alt="" /></div>
    </div>
    </>
}

export default  ContactsPage;